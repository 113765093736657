import React, { useEffect } from "react";
import Footer from "./Footer";
import Aos from "aos";
import { useTranslation } from "react-i18next";

const Policy = () => {
  const { t } = useTranslation();
  useEffect( () =>{
    Aos.init();
  },[])

  return (
    <div className="faq">
      <div className="faq-image">
        <div className="image-overlay"></div>
        <div className="faq-section-one">
          <h1>{t("privacy_policy")}</h1>
          <p>{t("privacy_policy_update")}</p>
        </div>
      </div>
      <div className="faq-container">
        <div className="faq-card">
          <h1
            className="my-1 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title1")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title1_text1")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title2")}
          </h1>
          {t("policy_title2_text", { returnObjects: true }).map(
            (point, index) => (
              <p
                className="text-light"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-easing="ease-in-sine"
                key={index}
              >
                {point}
              </p>
            )
          )}
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title3")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title3_text1")}
          </p>
          <ol
            className="px-1 text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            <li>
              {t("policy_title3_subtitle1")}
              <ul className="py-1">
                <li>{t("policy_title3_subtitle1_point1")}</li>
                <li>{t("policy_title3_subtitle1_point2")}</li>
                <li>{t("policy_title3_subtitle1_point3")}</li>
              </ul>
            </li>
            <li>
              {t("policy_title3_subtitle2")}
              <ul className="py-1">
                <li>{t("policy_title3_subtitle2_point1")}</li>
                <li>{t("policy_title3_subtitle2_point2")}</li>
              </ul>
            </li>
            <li>
              {t("policy_title3_subtitle3")}
              <ul className="py-1">
                <li>{t("policy_title3_subtitle3_point1")}</li>
              </ul>
            </li>
            <li>
              {t("policy_title3_subtitle4")}
              <ul className="py-1">
                <li>{t("policy_title3_subtitle4_point1")}</li>
              </ul>
            </li>
            <li>
              {t("policy_title3_subtitle5")}
              <ul className="py-1">
                <li>{t("policy_title3_subtitle5_point1")}</li>
              </ul>
            </li>
            <li>
              {t("policy_title3_subtitle6")}
              <ul className="py-1">
                <li>{t("policy_title3_subtitle6_point1")}</li>
              </ul>
            </li>
            <li>
              {t("policy_title3_subtitle7")}
              <ul className="py-1">
                <li>{t("policy_title3_subtitle7_point1")}</li>
              </ul>
            </li>
            <li>
              {t("policy_title3_subtitle8")}
              <ul className="py-1">
                <li>{t("policy_title3_subtitle8_point1")}</li>
              </ul>
            </li>
          </ol>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title4")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title4_text1")}
          </p>

          <ul
            className="px-1 text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title4_text1_points", { returnObjects: true }).map(
              (point, index) => (
                <li key={index}>{point}</li>
              )
            )}
          </ul>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title5")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title5_text1")}
          </p>

          <ul
            className="px-1 text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title5_text1_points", { returnObjects: true }).map(
              (point, index) => (
                <li key={index}>{point}</li>
              )
            )}
          </ul>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title6")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title6_text1")}
          </p>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title7")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title7_text1")}
          </p>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title8")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title8_text1")}
          </p>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title9")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title9_text1")}
          </p>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title10")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title10_text1")}
          </p>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title11")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title11_text1")}
          </p>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title12")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title12_text1")}
          </p>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title13")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title13_text1")}
          </p>

          <ul
            className="px-1 text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title13_text1_points", { returnObjects: true }).map(
              (point, index) => (
                <li key={index}>{point}</li>
              )
            )}
          </ul>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title14")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title14_text1")}
          </p>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title14_text2")}
          </p>

          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title15")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("policy_title15_text1")}
          </p>

          {/* <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("why_we_collect")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("why_we_collect_text")}
          </p>
          <ul
            className="px-1 text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            <li>{t("reason1")}</li>
            <li>{t("reason2")}</li>
            <li>{t("reason3")}</li>
            <li>{t("reason4")}</li>
          </ul>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("safe_data")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("safe_data_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("cookie_policy")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("cookie_policy1")}
            <br></br>
            <br></br>
            {t("cookie_policy2")}
            <br />
            <br />
            {t("cookie_policy3")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("links_to_other_sites")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("links_to_other_sites_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("restriction_of_data")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("restriction_of_data_text1")}
            <br />
            <br />
            {t("restriction_of_data_text2")}
            <br />
            <br />
            {t("restriction_of_data_text3")}
            <br />
            <br />
            {t("restriction_of_data_text4")}
          </p> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;