import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import InterestImg from "../Assets/human-brain.png"

const Step5 = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [interests, setInterests] = useState(formData.interests);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        "https://us-central1-aroundu-app.cloudfunctions.net/v1public/cache/categories/all"
      );
      const data = await response.json();
      setCategories(data.data);
    }
    fetchData();
  }, []);

  const handleChange = (interest) => {
    let updatedInterests;
    if (interests.includes(interest)) {
      updatedInterests = interests.filter((item) => item !== interest);
    } else {
      updatedInterests = [...interests, interest];
    }
    setInterests(updatedInterests);
    setFormData({ ...formData, interests: updatedInterests });
  };

  return (
    <div>
      <div className="arounder-carousel-card">
        <img src={InterestImg} alt="" width={100} />
        <h2>{t("choose_interest")}</h2>
        <p className="text-light my-1">{t("choose_interest_text")}</p>
      </div>
      {/* <h2>{t("choose_interest")}</h2>
      <p className="text-light my-1">{t("choose_interest_text")}</p> */}
      <FormGroup>
        {categories.map((category) => (
          <FormControlLabel
            key={category.sid}
            control={<Checkbox />}
            label={category.name + " - " + category.name_ar}
            name="interests"
            checked={interests.includes(category.name)}
            onChange={() => handleChange(category.name)}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default Step5;
