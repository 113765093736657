import React from "react";
import Scroll2 from "./Scroll2";
import Download from "./Download";
import Service from "./Service";
import Footer from "./Footer";

const Hero = () => {
    return (
      <>
      <Scroll2 />
      <Download />
      <Service />
      <Footer />
      </>
      
    );
  };
  
  export default Hero;