import React, { useEffect } from "react";
import Footer from "./Footer";
import Aos from "aos";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <div className="faq">
      <div className="faq-image">
        <div className="image-overlay"></div>
        <div className="faq-section-one">
          <h1>{t("terms")}</h1>
          <p>{t("terms_update")}</p>
        </div>
      </div>
      <div className="faq-container">
        <div className="faq-card">
          <h1
            className="my-1 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("terms_use")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("terms_use_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("limitation_liability")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("limitation_liability_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("website_terms")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("website_terms_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("safe_data")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("safe_data_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("website_use_title")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("website_use_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("trademark_and_rights")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("trademark_and_rights_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("details_supplied")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("details_supplied_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("international_user")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("international_user_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("applicable_law")}
          </h1>
          <p
            className="text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("applicable_law_text")}
          </p>
          <h1
            className="my-2 font-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            {t("payment_gateway")}
          </h1>
          <ul
            className="px-1 my-1 text-light"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            <li>{t("payment_gateway_point1")}</li>
            <li>{t("payment_gateway_point2")}</li>
            <li>{t("payment_gateway_point3")}</li>
            <li>{t("payment_gateway_point4")}</li>
            <li>{t("payment_gateway_point5")}</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;