import React from "react";
import Logo from "../Assets/logo_white.png";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import { IconContext } from "react-icons";
import AppStore from "../Assets/app_store_white.png";
import PlayStore from "../Assets/google_play_white.png";
import AppStoreAr from "../Assets/app_store_white_ar.png";
import PlayStoreAr from "../Assets/google_play_white_ar.png";
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  // Use new variables to handle different languages
  const appStore = i18n.language === "en" ? AppStore : AppStoreAr;
  const playStore = i18n.language === "en" ? PlayStore : PlayStoreAr;
  // const comingSoon = () => {
  //   toast.info('App coming soon!', {
  //     position: "top-center",
  //     autoClose: 2000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: false,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //     });
  // }
  return (
    <footer className="footer">
      {/* First part of the footer */}
      <div className="footer-top">
        <div className="footer-column">
          <img src={Logo} alt="Logo" className="footer-logo" />
          <p className="tagline">{t("footer_tagline")}</p>
          <div className="footer-icons">
            <IconContext.Provider
              value={{ color: "black", className: "react-icons" }}
            >
              <a
                href="https://www.linkedin.com/company/aroundUHub/"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn"
              >
                <span className="social-media-icons">
                  <FaLinkedinIn style={{ color: "#fff", fontSize: "20px" }} />
                </span>
              </a>
              <a
                href="https://www.instagram.com/aroundUHub/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="social-media-icons">
                  <FaInstagram style={{ color: "#fff", fontSize: "20px" }} />
                </span>
              </a>
              <a
                href="https://twitter.com/aroundUHub"
                target="_blank"
                rel="noreferrer"
              >
                <span className="social-media-icons">
                  <FaXTwitter style={{ color: "#fff", fontSize: "20px" }} />
                </span>
              </a>
              <a
                href="https://www.facebook.com/aroundUHub"
                target="_blank"
                rel="noreferrer"
              >
                <span className="social-media-icons">
                  <FaFacebookF style={{ color: "#fff", fontSize: "20px" }} />
                </span>
              </a>
              <a
                href="https://www.tiktok.com/@aroundUHub"
                target="_blank"
                rel="noreferrer"
              >
                <span className="social-media-icons">
                  <FaTiktok style={{ color: "#fff", fontSize: "20px" }} />
                </span>
              </a>
            </IconContext.Provider>
          </div>
        </div>
        <div className="footer-column">
          <h3>{t("footer_column1")}</h3>
          <ul>
            <li>
              <a className="underline-hover" href="/">
                {t("home")}
              </a>
            </li>
            <li>
              <a className="underline-hover" href="/about">
                {t("about")}
              </a>
            </li>
            <li>
              <a className="underline-hover" href="/contact">
                {t("contact")}
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>{t("footer_column2")}</h3>
          <ul>
            {/* <li><a href="/faq">FAQ</a></li> */}
            <li>
              <a className="underline-hover" href="/policy">
                {t("privacy")}
              </a>
            </li>
            <li>
              <a className="underline-hover" href="/terms">
                {t("terms")}
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Separator line */}
      <hr />

      {/* Second part of the footer */}
      <div className="footer-bottom">
        <div className="footer-left">
          <a href="https://apps.apple.com/us/app/aroundu/id6470918157">
            <img
              src={appStore}
              alt=""
              className="store-button"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=app.aroundu">
            <img
              src={playStore}
              alt=""
              className="store-button store-btn-apple"
            />
          </a>
        </div>
        <div className="footer-right">
          <p>
            {t("copyright")} &copy; {t("copyright_text")}{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;