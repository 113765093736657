import React, { useState, useEffect, useMemo } from "react";
import Illustration1 from "../Assets/Frame1.png";
import Illustration2 from "../Assets/Frame2.png";
import Illustration3 from "../Assets/Frame3.png";
import Illustration4 from "../Assets/Frame4.png";
import Illustration5 from "../Assets/Frame5.png";
import Illustration6 from "../Assets/Frame6.png";
import Illustration1Ar from "../Assets/Frame1(AR).png";
import Illustration2Ar from "../Assets/Frame2(AR).png";
import Illustration3Ar from "../Assets/Frame3(AR).png";
import Illustration4Ar from "../Assets/Frame4(AR).png";
import Illustration5Ar from "../Assets/Frame5(AR).png";
import Illustration6Ar from "../Assets/Frame6(AR).png";
import "./App.css";
import { useTranslation } from "react-i18next";

const Scroll2 = () => {
  const { t, i18n } = useTranslation();

  // Use new variables to handle different languages
  const illustration1 =
    i18n.language === "ar" ? Illustration1Ar : Illustration1;
  const illustration2 =
    i18n.language === "ar" ? Illustration2Ar : Illustration2;
  const illustration3 =
    i18n.language === "ar" ? Illustration3Ar : Illustration3;
  const illustration4 =
    i18n.language === "ar" ? Illustration4Ar : Illustration4;
  const illustration5 =
    i18n.language === "ar" ? Illustration5Ar : Illustration5;
  const illustration6 =
    i18n.language === "ar" ? Illustration6Ar : Illustration6;

  const serviceInfoData = useMemo(
    () => [
      {
        image: illustration1,
        title: t("serviceInfo[0].title"),
        text: t("serviceInfo[0].text"),
      },
      {
        image: illustration2,
        title: t("serviceInfo[1].title"),
        text: t("serviceInfo[1].text"),
      },
      {
        image: illustration3,
        title: t("serviceInfo[2].title"),
        text: t("serviceInfo[2].text"),
      },
      {
        image: illustration4,
        title: t("serviceInfo[3].title"),
        text: t("serviceInfo[3].text"),
      },
      {
        image: illustration5,
        title: t("serviceInfo[4].title"),
        text: t("serviceInfo[4].text"),
      },
      {
        image: illustration6,
        title: t("serviceInfo[5].title"),
        text: t("serviceInfo[5].text"),
      },
      // Add more service info data as needed
    ],
    [
      t,
      illustration1,
      illustration2,
      illustration3,
      illustration4,
      illustration5,
      illustration6,
    ]
  );

  const [activeSection, setActiveSection] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(illustration1);
  const [showImg, setShowImg] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Get the position of the scroll
      const scrollPosition = window.scrollY;

      // Determine which section is currently in view based on scroll position
      const currentSection = Math.floor(scrollPosition / window.innerHeight);

      // Update the background image and active section
      if (currentSection >= 0 && currentSection < serviceInfoData.length) {
        setBackgroundImage(serviceInfoData[currentSection].image);
        setActiveSection(currentSection);
        setShowImg(true); // Show indicators when scrolling
      } else {
        setShowImg(false); // Hide indicators when not scrolling in sections
      }
    };

    // Listen for scroll events
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [serviceInfoData]);

  return (
    <section className="App-scroll">
      {showImg && (
        <div className="home-section-img">
          <img src={backgroundImage} alt="" />
        </div>
      )}
      {serviceInfoData.map((section, index) => (
        <div
          key={index}
          className={`section ${activeSection === index ? "active" : ""}`}
        >
          <div className="home-banner-container">
            <div className="home-text-section">
              <h1 className="primary-heading hero-title">{section.title}</h1>
              <p className="primary-text">{section.text}</p>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Scroll2;
