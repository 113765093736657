import React, { useEffect } from "react";
import ArrowRight from "../Assets/angle-up.png";
import Person1 from "../Assets/Man1.png";
import Person2 from "../Assets/Man2.png";
import { FaSuitcase } from "react-icons/fa6";
import Aos from "aos";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const Service = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init();
  }, []);
  const comingSoon = () => {
    toast.info("Coming soon!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  return (
    <section className="service-container">
      <div className="service-card" id="#captain">
        <div className="card-left" data-aos="fade-up" data-aos-delay="100">
          <div className="card-heading">{t("service_title2")}</div>
          <p className="card-text">{t("servive_desc2")}</p>
          <a href="/arounder-registration" style={{ color: "#000000" }}>
            <div className="service-card-btn">
              <div className="card-arrow">
                <img src={ArrowRight} alt="" />
              </div>
              <div className="card-btn-text">
                <p>{t("service_button2")}</p>
              </div>
              <div className="card-btn-icon">
                <FaSuitcase />
              </div>
            </div>
          </a>
        </div>
        <div className="card-right">
          <div className="oval">
            <img src={Person2} alt="" />
          </div>
        </div>
      </div>
      <div className="service-card" id="#partner">
        <div className="card-left" data-aos="fade-up" data-aos-delay="100">
          <div className="card-heading">{t("service_title1")}</div>
          <p className="card-text">{t("service_desc1")}</p>
          <div className="service-card-btn" onClick={comingSoon}>
            <div className="card-arrow">
              <img src={ArrowRight} alt="" />
            </div>
            <div className="card-btn-text">
              <p>{t("service_button1")}</p>
            </div>
            <div className="card-btn-icon">
              <FaSuitcase />
            </div>
          </div>
        </div>
        <div className="card-right">
          <div className="oval">
            <img src={Person1} alt="" id="person1" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
