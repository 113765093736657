import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar2 from "./Components/Navbar2";
import Hero from "./Components/Hero";
// import Faq from './Components/Faq';
import Policy from "./Components/Policy";
import Terms from "./Components/Terms";
import Loader from "./Components/Loader";
import Contact from "./Components/Contact";
import About from "./Components/About";
import ArounderRegistration from "./Components/ArounderRegistration";
import Error404 from "./Components/Error404";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Illustration1 from "./Assets/Frame1.png";
import Illustration2 from "./Assets/Frame2.png";
import Illustration3 from "./Assets/Frame3.png";
import Illustration4 from "./Assets/Frame4.png";
import Illustration5 from "./Assets/Frame5.png";
import Illustration6 from "./Assets/Frame6.png";
import Illustration1Ar from "./Assets/Frame1(AR).png";
import Illustration2Ar from "./Assets/Frame2(AR).png";
import Illustration3Ar from "./Assets/Frame3(AR).png";
import Illustration4Ar from "./Assets/Frame4(AR).png";
import Illustration5Ar from "./Assets/Frame5(AR).png";
import Illustration6Ar from "./Assets/Frame6(AR).png";
import { ToastContainer } from "react-toastify";
import "./i18n";
import { useTranslation } from "react-i18next";

function App() {
  const [isImagesLoaded, setIsImagesLoaded] = useState(false);
  const { i18n } = useTranslation();
  console.log(i18n.language);

  useEffect(() => {
    // Preload images
    var imageUrls = [];
    if (i18n.language === "ar") {
      imageUrls = [
        Illustration1Ar,
        Illustration2Ar,
        Illustration3Ar,
        Illustration4Ar,
        Illustration5Ar,
        Illustration6Ar,
      ];
    } else {
      imageUrls = [
        Illustration1,
        Illustration2,
        Illustration3,
        Illustration4,
        Illustration5,
        Illustration6,
      ];
    }

    const images = [];
    let imagesLoaded = 0;

    const preloadImages = () => {
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          imagesLoaded++;
          if (imagesLoaded === imageUrls.length) {
            setIsImagesLoaded(true);
          }
        };
        images.push(img);
      });
    };

    preloadImages();

    return () => {
      // Clean up event listeners or intervals if any
      images.forEach((img) => {
        img.onload = null;
      });
    };
  }, [i18n.language]);

  // Render preloader while images are loading
  if (!isImagesLoaded) {
    return <Loader />;
  }

  return (
    <Router>
      <div className="App">
        <meta
          name="keywords"
          content="React, JavaScript, semantic markup, html"
        />
        <Navbar2 />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/home" element={<Hero />} />
          {/* <Route path="/faq" element={<Faq />} /> */}
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/arounder-registration"
            element={<ArounderRegistration />}
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Router>
  );
}

export default App;
