import React from "react";
import { useTranslation } from "react-i18next";
import CompanyImg from "../Assets/cooperation.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const Step4 = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <div className="arounder-carousel-card">
        <img src={CompanyImg} alt="" width={100} />
        <h2>
          {t("company_representative")}{" "}
          <span className="text-light">({t("optional")})</span>
        </h2>
        <p className="text-light my-1">{t("company_representative_text")}</p>
      </div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          name="company_name"
          label={t("company_name")}
          value={formData.company_name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <TextField
          type="email"
          name="company_email"
          label={t("company_email")}
          value={formData.company_email}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        </Box>
      {/* <label>
        {t("company_name")}:
        <input
          type="text"
          name="company_name"
          value={formData.company_name}
          onChange={handleChange}
        />
      </label>
      <label>
        {t("company_email")}:
        <input
          type="email"
          name="company_email"
          value={formData.company_email}
          onChange={handleChange}
        />
      </label> */}
    </div>
  );
};

export default Step4;
