import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ArounderImg1 from "../Assets/register.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { countries } from "../countries";
import axios from "axios";

const Step3 = ({ formData, setFormData, isError, setIsError }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [isMailError, setIsMailError] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [mailHelperText, setMailHelperText] = useState("");
  const [phoneHelperText, setPhoneHelperText] = useState("");

  const { t } = useTranslation();

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "refcode") {
      e.target.value = e.target.value.toUpperCase();
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleAutocompleteChange = (event, newValue) => {
    setValue(newValue);
    setFormData({
      ...formData,
      country_code: newValue ? `+${newValue.phone}` : "",
    });
    setInputValue(newValue ? `+${newValue.phone}` : "");
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const checkEmailOrTelephone = async (name, value) => {
    // var { name, value } = e.target;
    console.log(name, value);
    if (name === "email" || name === "tel") {
      if (name === "email") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate the input value
        if (!emailPattern.test(value)) {
          setIsMailError(true);
          setMailHelperText("Please enter a valid email address.");
          setIsError(true);
          return;
        } else {
          setIsMailError(false);
          setMailHelperText("");
          setIsError(false);
        }
      }
      if (name === "tel") {
        value = formData.country_code + value;
      }
      try {
        const response = await axios.get(
          `https://us-central1-aroundu-app.cloudfunctions.net/v1public/user/isRegistered`,
          {
            headers: {
              "Content-Type": "application/json", // Adjust content type if necessary
            },
            params: {
              identifier: name,
              identity: value,
            },
          }
        );
        if (response.data.code === "200" && name === "tel") {
          setIsPhoneError(true);
          setPhoneHelperText("Telephone is already registered");
          setIsError(true);
        } else if (response.data.code === "200" && name === "email") {
          setIsMailError(true);
          setMailHelperText("Email is already registered");
          setIsError(true);
        }
      } catch (error) {
        if (name === "tel") {
          setIsPhoneError(false);
          setPhoneHelperText("");
          setIsError(false);
        } else if (name === "email") {
          console.log("mail not focused");
          setIsMailError(false);
          setMailHelperText("");
          setIsError(false);
        }
        console.error(error);
      }
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedValidateInput = debounce(checkEmailOrTelephone, 500); // 500ms debounce

  const handleEmailTelInput = (e) => {
    var { name, value } = e.target;
    debouncedValidateInput(name, value);
  };

  return (
    <div>
      <div className="arounder-carousel-card">
        <img src={ArounderImg1} alt="" width={100} />
        <h2>{t("register_arounder_title")}</h2>
        <p className="text-light my-1">{t("register_arounder_text")}</p>
      </div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { my: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          name="f_name"
          label={t("f_name")}
          value={formData.f_name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          name="l_name"
          label={t("l_name")}
          value={formData.l_name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          type="email"
          name="email"
          label={t("email")}
          value={formData.email}
          onChange={handleChange}
          // onBlur={validateEmailOrTelephone}
          onInput={handleEmailTelInput}
          variant="outlined"
          error={isMailError}
          helperText={mailHelperText}
          fullWidth
          required
          inputProps={{ style: { textTransform: "lowercase" } }}
        />

        <FormControl fullWidth>
          <InputLabel id="gender-select-label">
            {t("select_gender")} *
          </InputLabel>
          <Select
            labelId="gender-select-label"
            name="gender"
            required
            value={formData.gender}
            label={t("select_gender")}
            onChange={handleChange}
          >
            <MenuItem key="male" value="male">
              Male
            </MenuItem>
            <MenuItem key="female" value="female">
              Female
            </MenuItem>
          </Select>
        </FormControl>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="country-select"
              options={countries}
              value={value}
              inputValue={inputValue}
              getOptionLabel={(option) =>
                value && value.phone === option.phone
                  ? `+${option.phone}`
                  : option.label
              }
              renderOption={(props, option) => (
                <li {...props}>
                  {option.label} (+{option.phone})
                </li>
              )}
              onChange={handleAutocompleteChange}
              onInputChange={handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("country_code")}
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              type="number"
              name="tel"
              label={t("telephone")}
              value={formData.tel}
              onChange={handleChange}
              onInput={handleEmailTelInput}
              variant="outlined"
              error={isPhoneError}
              helperText={phoneHelperText}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <TextField
          type="text"
          name="refcode"
          label={t("ref_code")}
          value={formData.refcode}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          inputProps={{
            style: { textTransform: "uppercase" },
            maxLength: 6,
          }}
        />
      </Box>
    </div>
  );
};

export default Step3;
