import React from "react";
import Error404Img from "../Assets/error.png";

const Error404 = () => {
  return (
    <div className="coming-section-container">
      <div className="coming-section-top">
        <img src={Error404Img} alt="" width={400} />
        <h2>Sorry, Page Not Found!</h2>
        <p>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
          mistyped the URL? Be sure to check your spelling.
        </p>
      </div>
    </div>
  );
};

export default Error404;