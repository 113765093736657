import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const changeLanguage = (lng) => {
    setDropdownOpen(false);
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    document.documentElement.setAttribute("dir", lng === "ar" ? "rtl" : "ltr");
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language);
    document.documentElement.setAttribute(
      "dir",
      i18n.language === "ar" ? "rtl" : "ltr"
    );
  }, [i18n.language]);

  return (
    <div className="language-switcher">
      <div className="globe-icon" onClick={toggleDropdown}>
        <FontAwesomeIcon icon={faGlobe} />
      </div>
      {dropdownOpen && (
        <div className="dropdown-menu">
          <button
            className={i18n.language === "en" ? "selected" : ""}
            onClick={() => changeLanguage("en")}
          >
            English
          </button>
          <button
            className={i18n.language === "ar" ? "selected" : ""}
            onClick={() => changeLanguage("ar")}
          >
            Arabic
          </button>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
