import React from "react";
import AboutImg from "../Assets/Who-are-we-illustartion.png";
import { useTranslation } from "react-i18next";
// import Carousel from "./Carousel";
// import Join from "./Join";
import Footer from "./Footer";
// import Partner from "./Partner";

const About = () => {
      const { t } = useTranslation();
      return (
        <>
          <section className="about-container">
            <div className="about-text">
              <h1 className="about-heading">
                {" "}
                <span className="green"> {t("who")}&nbsp;</span>{t("are_we")}
              </h1>
              <p className="about-desc">{t("about_desc")}</p>
            </div>
            <div className="about-img">
              <img src={AboutImg} alt="about img" />
            </div>
          </section>
          <section>
            <div className="random"></div>
          </section>
          {/* <section className="about-team">
                        <h1 className="about-heading" style={{ display: "flex", justifyContent: "center", color: "#fff" }}>Meet our&nbsp;<span className="white"> team</span></h1>
                        <Carousel />
                  </section>

                  <section className="partner-section">
                        <h1 className="partner-heading">Who we are engaged with</h1>
                        <p className="partner-tagline">We partner with humble, trusting leaders that think strategically.
                              Businessmen and women who believe in the change they’re making,
                              embrace their mission and want to bring in a partner to accelerate their growth.</p>
                        <Partner />
                  </section> */}
          {/* <Join /> */}
          <Footer />
        </>
      );
};

export default About;